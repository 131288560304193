<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Création de métadonnées
    </v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <div v-if="parameters.length">
          <h4>
            Paramètre(s) disponible(s) :
          </h4>

          <div>
            <ul>
              <li
                v-for="(parameter, parameterIndex) in parameters"
                :key="parameterIndex"
              >
                {{ parameter.name }} ({{ parameter.description }})
              </li>
            </ul>
          </div>
          <v-row>
            <v-col
              cols="10"
              md="10"
            >
              <v-divider class="v-divider-entity mt-5 mb-2"></v-divider>
            </v-col>
          </v-row>
        </div>

        <p v-if="errors && errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title ? error.title : error.message }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="10"
            md="10"
          >
            <v-select
              v-model="route"
              :items="availableRoutes"
              label="Page"
              class="mt-5 mb-5"
              outlined
              persistent-hint
              @change="loadRouteMetadataParameters(route)"
            ></v-select>

            <v-text-field
              v-model="title[currentLocale]"
              label="Titre"
              class="mb-5"
              outlined
              dense
              placeholder="Titre"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-text-field
              v-model="description[currentLocale]"
              label="Description"
              class="mb-5"
              outlined
              dense
              placeholder="Description"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-text-field
              v-model="keywords[currentLocale]"
              label="Mot clés"
              class="mb-5"
              outlined
              dense
              placeholder="Mot clés"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'metadataList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'
import router from '@/router'
import LocalSwitcher from '@/components/Common/LocalSwitcher'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    LocalSwitcher,
    Snackbar,
    Loader
  },
  data () {
    return {
      valid: false,
      currentLocale: 'fr',
      errors: [],
      route: null,
      availableRoutes: [],
      metadataParameter: null,
      parameters: [],
      title: [],
      description: [],
      keywords: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      entityData: {
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {}
      }
    }
  },
  created () {
    this.getRoutes()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (!this.route) {
        this.errors.push({ title: 'La page doit être renseignée.' })

        return
      }

      if (this.valid) {
        this.postMetadata()
      }
    },
    postMetadata () {
      this.valid = false
      this.loading = true

      this.handleTranslations([
        'title',
        'description',
        'keywords'
      ])

      this.entityData.route = this.route

      Vue.prototype.$http
        .post(config.apiUrl + '/api/seo/metadata', this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 201) {
            const metadata = response.data

            this.errors = []
            this.snackbar = true
            this.successMessage = 'Métadonnées créées avec succès.'

            setTimeout(() => {
              router.push({ name: 'metadataDetails', params: { metadataId: metadata.id } })
            }, 1500)
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data

            if (data.violations) {
              this.errors = data.violations
            } else {
              this.errors = data
            }
          }

          this.valid = true
          this.loading = false
        })
    },
    getRoutes () {
      Vue.prototype.$http
        .get(config.apiUrl + '/api/routes', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              for (const [currentValue, currentLabel] of Object.entries(data)) {
                this.availableRoutes.push({
                  text: currentLabel,
                  value: currentValue
                })
              }
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }
        })
    },
    loadRouteMetadataParameters (route) {
      Vue.prototype.$http
        .get(config.apiUrl + '/api/seo/metadata-parameters/' + route, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.parameters = []
            this.metadataParameter = response.data

            for (const [currentParameterId] of Object.entries(this.metadataParameter.parameters)) {
              this.parameters.push(this.metadataParameter.parameters[currentParameterId])
            }

            this.errors = []
          }
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }
        })
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = {...currentObjState, ...translation}
        }
      }
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    }
  }
}
</script>
